export default [
  {
    path: 'home',
    label: 'Home',
  },
  {
    path: 'kroki',
    label: 'Jak zdobyć',
  },  
  {
    path: 'odwiedzaj',
    label: 'Zwiedzaj',
  },
  {
    path: 'odznaka',
    label: 'Odznaki',
  },
  {
    path: 'mapa',
    label: 'Mapa',
  },
   
  {
    path: 'historia',
    label: 'Historia',
  },
];
