/** @jsx jsx */
// import { jsx, Container, Flex, Box } from 'theme-ui';
import { jsx, Container, Box } from 'theme-ui';
import { keyframes } from '@emotion/core';
import { Link } from 'react-scroll';
import Logo from 'components/logo';
// import LogoDark from 'assets/logo-dark.svg';
// import LogoWhite from 'assets/logo.svg';
import { DrawerProvider } from '../../contexts/drawer/drawer.provider';
import MobileDrawer from './mobile-drawer';
import menuItems from './header.data';
// import LogoSBW from 'assets/logo.png';
import LogoMlawa from 'assets/logo_mlawa.png';
// import LogoPTTK from 'assets/znak_pttk70.png';
// import Herbs from 'assets/herbs.png';
import Herb1 from 'assets/pttk.png';
import Herb2 from 'assets/miasto_mlawa.png';
import Herb3 from 'assets/wieczfnia_koscielna.png';
import Herb4 from 'assets/ilowo_osada.png';
import Herb5 from 'assets/lipowiec_koscielny.png';
import Herb6 from 'assets/szydlowo.png';
import Herb7 from 'assets/nadlesnictwo_dwukoly.png';

const herbs = [
  {
    id:1,
    img:  Herb1,
    link: 'https://pttk.com.pl'
  },
  {
    id:2,
    img:  Herb2,
    link: 'https://mlawa.pl'
  },
  {
    id:3,
    img:  Herb3,
    link: 'https://wieczfniakoscielna.pl/'
  },
  {
    id:4,
    img:  Herb4,
    link: 'https://ilowo-osada.pl/'
  },
  {
    id:5,
    img:  Herb5,
    link: 'https://lipowieckoscielny.pl/'
  },
  {
    id:6,
    img:  Herb6,
    link: 'https://szydlowo-maz.pl/'
  },
  {
    id:7,
    img:  Herb7,
    link: 'https://dwukoly.olsztyn.lasy.gov.pl/ '
  },
]

export default function Header({ className }) {
  return (
    <DrawerProvider>
      <header sx={styles.header} className={className} id="header">
        <Container sx={styles.container}>
          {/* <Logo src={className === 'sticky' ? LogoDark : LogoWhite} /> */}
          {/* <Logo src={LogoPTTK}  alt="PTTK-Mazowsze" /> */}
          {/* <Logo src={LogoSBW} alt="MŁAWA" link="\" /> */}
          <Logo src={LogoMlawa} width={"200px"} alt="MŁAWA" link="\" />


          
          {/* <Box sx={{flex:'block'}}> */}
            
            {/* <Box margin={1}  > */}
            {/* <Box sx={{ml:5, minWidth:'45%'}}  > */}
            {/* <Logo width={"60px"} src={NasielskHerb} alt="Nasielsk" link="https://nasielsk.pl" /> */}

            {/* <Flex as="nav" sx={styles.nav}> */}
            <Box as="nav" sx={styles.nav}>
            {menuItems.map(({ path, label }, i) => (
              <Link
                activeClass="active"
                to={path}
                spy={true}
                smooth={true}
                offset={-180}
                duration={500}
                key={i}
              >
                {label}
              </Link>
            ))}
            {/* </Flex> */}
            </Box>          
              
            {/* </Box> */}
            
          {/* </Box> */}

          {herbs.map((item) => (
            // <Box sx={styles.card} key={item.id}>
            <Box  key={item.id}>
              <Logo  src={item.img} ml={"auto"} mr={0} width={"100px"} alt="Herby" link={item.link} target='_blank`'/>
            </Box>
          ))}

          {/* <Box ml={"auto"} mr={0} > */}
              {/* <Logo width={"60px"} src={LogoPTTK} alt="PTTK-Mazowsze" link="https://pttk.com.pl/" /> */}
              {/* <Logo  src={Herbs} ml={"auto"} mr={0} width={"500px"} alt="Herby" link="https://mlawa.pl" /> */}              
          {/* </Box> */}
           

          <MobileDrawer />
        </Container>
      </header>
    </DrawerProvider>
  );
}

const positionAnim = keyframes`
  from {
    position: fixed;
    opacity: 1;
  }
  to {
    position: absolute;
    opacity: 1;
    transition: all 0.4s ease;
  }
`;

const styles = {
  header: {
    // color: 'white',
    color: 'black',
    fontWeight: 'normal',
    py: 4,
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    // backgroundColor: 'transparent',
    backgroundColor: 'white',
    transition: 'all 0.5s ease',
    animation: `${positionAnim} 0.4s ease`,
    '.donate__btn': {
      flexShrink: 0,
      mr: [15, 20, null, null, 0],
      ml: ['auto', null, null, null, 0],
    },
    '&.sticky': {
      position: 'fixed',
      backgroundColor: 'background',
      // backgroundColor: 'border_color',
      color: '#000000',
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.06)',
      py: 3,
      'nev > a': {
        color: 'text',
      },
      '.donate__btn': {
        borderColor: 'primary',
        color: 'primary',
        '&:hover': {
          boxShadow: 'rgba(31, 62, 118, 0.57) 0px 9px 20px -5px',
          // backgroundColor: 'primary',
          // backgroundColor: 'border_color',
          backgroundColor: 'background',
          color: 'white',
        },
      },
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  nav: {
    mx: 'auto',
    display: 'none',
    '@media screen and (min-width: 1024px)': {
      display: 'block',
    },
    minWidth: '50%',
    a: {
       // fontSize: '12px',
      fontSize: '2vm',
      fontWeight: '400',
      px: 12,
      cursor: 'pointer',
      lineHeight: '1.2',
      '&.active': {
        color: 'secondary',        
      },
    },
  },
};
