/** @jsx jsx */
// import { jsx, Heading, Box, Container, Text, Image } from 'theme-ui';
import { jsx, Text } from 'theme-ui';
import { Link } from 'components/link';
// import menuItems from './footer.data';
// import Logo from 'components/logo';
// import Image from 'components/image';
// import LogoMazowsze from 'assets/logo-mazowsze.png';

export default function Footer() {
  return (
    <footer sx={styles.footer}>
      {/* <Container sx={styles.footer.container}> */}
        {/* <Box sx={styles.footer.footerTopArea}>
          {menuItems.map(({ header, items }, i) => (
            <Box sx={styles.footer.menus} key={i}>
              <Heading sx={styles.footer.heading}>{header}</Heading>
              <nav>
                {items.map(({ path, label }, i) => (
                  <Link
                    path={path}
                    key={i}
                    label={label}
                    sx={styles.footer.link}
                  />
                ))}
              </nav>
            </Box>
          ))}
        </Box> */}
        {/* <Logo sx={styles.footer.img} src={LogoMazowsze} /> */}
        {/* <Logo src={LogoMazowsze} /> */}

        {/* 
        <Box sx={styles.footer.img}>
        <Link path="https://play.google.com/store/apps/details?id=pl.globallab.bw1920" >         
          <Image  sx={{
            //display: 'block',
            //marginLeft: 'auto',
            //marginRight: 'auto',
            width: '230px',
            }}          
            src="https://play.google.com/intl/en_us/badges/static/images/badges/pl_badge_web_generic.png" alt="Google Play" />
        </Link>
        <Link path="https://apps.apple.com/us/app/szlak-bitwy-warszawskiej/id1546989767" >          
          <Image  sx={{
            //display: 'block',
            //marginLeft: 'auto',
            //marginRight: 'auto',
            width: '200px',
            }}          
            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/pl-pl?size=250x83&amp;releaseDate=1611360000&h=8cdf7f21d958c40fce7e922e247397a0" alt="Google Play" />
        </Link>
        </Box>         
        */}
        
        {/* <Image sx={styles.footer.img}  src={LogoMazowsze} /> */}

        <Text sx={styles.footer.copyright}>
      
        Zadanie zostało sfinansowane przez Miasto Mława oraz gminy Iłowo-Osada, Lipowiec Kościelny, Szydłowo i Wieczfnia Kościelna.<br/>
      
        {/* <Link path="https://nogravity.io/" target="_blank">
          Global-Lab
        </Link> */}
        {/* <Link path="/termsofuse" target="_blank">
          Regulamin
        </Link> */}
        <Link path="/privacypolicy" target="_blank">
          Polityka prywatności
        </Link>

      </Text>
      
        {/* </Container> */}
    </footer>
  );
}

const styles = {
  footer: {
    container: {
      width: '100%',
      alignItems: 'stretch',
      backgroundColor:'border_color',
    },
    img:{
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      // width: '40%',
    },
    footerTopArea: {
      borderTop: '1px solid',
      borderTopColor: 'border_color',
      display: 'flex',
      flexWrap: 'wrap',
      pt: [7, null, 8],
      pb: ['10px', null, null, '20px'],
      px: [0, null, null, null, 4],
    },
    menus: {
      width: ['50%', null, null, '25%'],
      display: 'flex',
      flexDirection: 'column',
      mb: ['40px', null, null, '60px'],
    },

    heading: {
      fontSize: [3, null, null, 4],
      color: 'text_secondary',
      fontWeight: '500',
      mb: [3, 4, 5, null, 6],
      lineHeight: '1.35',
    },

    link: {
      fontSize: ['14px', null, 1],
      color: 'text',
      fontWeight: 'body',
      mb: 2,
      cursor: 'pointer',
      transition: 'all 0.35s',
      display: 'block',
      textDecoration: 'none',
      lineHeight: [1.5, null, null, 1.6, 1.8],
      ':hover': {
        color: 'primary',
      },
      ':last-child': {
        mb: '0px',
      },
    },
    copyright: {
      fontSize: ['14px', null, 1],
      width: '100%',
      textAlign: 'center',
      p: ['20px 20px'],
      backgroundColor: '#FCFDFE',
      color: 'text',
      a: {
        textDecoration: 'none',
        color: 'inherit',
        pl: 1,
        transition: 'all 0.25s',
        '&:hover': {
          color: 'primary',
        },
      },
    },
   
  },
};
