/** @jsx jsx */
import { jsx, Image } from 'theme-ui';
import { Link } from 'components/link';

export default function Logo({ src, alt, link, width, ...rest }) {
  return (
    <Link
      // path="\"
      // href="\"      
      href={link}      
      // path={link}
      // to={link}
      to=''
      sx={{
        variant: 'links.logo',
        display: 'flex',
        cursor: 'pointer',
        // mr: 15,
        // width: '50%',
        // width: ['30%','30%','30%','30%','50%','75%','75%','75%'],
        // height: '100%',
        // height: 'auto',
        // height: '100px',
        alignItems: 'stretch',
      }}
      {...rest}
    >
      {/* <Image src={src} alt="PTTK-Mazowsze" /> */}
      <Image sx={{
        display: 'flex',
        // ml: 15,
        // width: '100%',
        // height: '100%',
        // height: 'auto',
        objectFit: 'contain',
        // objectFit: 'scale-down',
        // objectFit: 'cover',
        }}
        src={src} alt={alt} 
        width={width}
      />
    </Link>
  );
}
